<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link
          :to="'/profile/education/update/' + $route.params.id+'/planner'"
          type="button"
        >
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Dosya Listesi</h3>
        <div class="card-options"></div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label for="filename" class="form-label">Dosya Başlığı</label>
              <input
                type="text"
                v-model="filename"
                placeholder="Dosya Adı"
                class="form-control"
                id="filename"
                autocomplete="off"
              />
            </div>
            <div class="form-group">
              <label for="fileway" class="form-label">Dosya Linki</label>
              <input
                type="text"
                v-model="fileway"
                placeholder="Dosya Yolu"
                class="form-control"
                id="fileway"
                autocomplete="off"
              />
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary"
                id="saveButton"
                @click="addFile"
              >
                Dosya Ekle
              </button>
            </div> <hr/>
          </div>
         
          <div class="col-sm-12 col-md-12" v-for="(item, i) in files" :key="i">
            <div class="form-group">
              <label for="filename" class="form-label">Dosya Başlığı</label>
              <input
                type="text"
                v-model="item.name"
                placeholder="Dosya Adı"
                class="form-control"
                id="filename"
                autocomplete="off"
              />
            </div>
            <div class="form-group">
              <label for="fileway" class="form-label">Dosya Linki</label>
              <input
                type="text"
                v-model="item.file"
                placeholder="Dosya Yolu"
                class="form-control"
                id="fileway"
                autocomplete="off"
              />
            </div>
            <div class="">
              <button
                class="btn btn-primary m-1"
                :id="'updateButton' + i"
                @click="updateFile(item, i)"
              >
                Dosya Güncelle
              </button>
              <button
                class="btn btn-primary m-1 float-right"
                :id="'removeButton' + i"
                @click="removeFile(item, i)"
              >
                Dosya Sil
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <style scoped>
</style>
  <script>
export default {
  created() {
    document.title = "Eğitim Dosyaları";
    let education = {
      id: this.$route.params.id,
    };
    this.$store.dispatch("educationFileGet", education).then((value) => {
      if (value.type == "success") {
        this.files = value.list;
      }
    });
  },
  data() {
    return {
      filename: "",
      fileway: "",
      files: [],
    };
  },
  methods: {
    addFile() {
      if (this.filename == null) {
        this.$vToastify.warning("Dosya adı girilmedi!", "Uyarı!");
      } else if (this.fileway == null) {
        this.$vToastify.warning("Dosya yolu girilmedi!", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML = "Dosya Ekleniyor...";
        let education = {
          id: this.$route.params.id,
          filename: this.filename,
          fileway: this.fileway,
        };
        this.$store.dispatch("educationFileAdd", education).then((value) => {
          if (value.type == "success") {
            document.getElementById("saveButton").disabled = false;
            document.getElementById("saveButton").innerHTML = "Dosya Ekle";
            this.$vToastify.success("Dosya Eklendi", "Başarılı!");
            this.files = value.list;
          }
        });
      }
    },
    updateFile(item, i) {
      if (item.name == null) {
        this.$vToastify.warning("Dosya adı girilmedi!", "Uyarı!");
      } else if (item.file == null) {
        this.$vToastify.warning("Dosya yolu girilmedi!", "Uyarı!");
      } else {
        document.getElementById("updateButton" + i).disabled = true;
        document.getElementById("updateButton" + i).innerHTML =
          "Dosya Kaydediliyor...";
        let education = {
          id: item.id,
          education_id: this.$route.params.id,
          filename: item.name,
          fileway: item.file,
        };
        this.$store.dispatch("educationFileUpdate", education).then((value) => {
          if (value.type == "success") {
            document.getElementById("updateButton" + i).disabled = false;
            document.getElementById("updateButton" + i).innerHTML =
              "Dosya Güncelle";
            this.$vToastify.success("Dosya Güncellendi", "Başarılı!");
            this.files = value.list;
          }
        });
      }
    },
    removeFile(item, i) {
      document.getElementById("removeButton" + i).disabled = true;
      document.getElementById("removeButton" + i).innerHTML =
        "Dosya Siliniyor...";
      let education = {
        id: item.id,
        education_id: this.$route.params.id,
      };
      this.$store.dispatch("educationFileRemove", education).then((value) => {
        if (value.type == "success") {
          document.getElementById("removeButton" + i).disabled = false;
          document.getElementById("removeButton" + i).innerHTML =
            "Dosya Güncelle";
          this.$vToastify.success("Dosya Silindi", "Başarılı!");
          this.files = value.list;
        }
      });
    },
  },
  components: {},
  mounted() {},
};
</script>